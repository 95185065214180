<template>
  <div class="certificate" v-loading="loading">
    <el-row :gutter="25">
      <el-col :span="6" v-for="(i, _i) in list" :key="_i" class="pic-box">
        <el-image
          @click="showBigPic(i)"
          :src="i.certImage"
          style="width: 100%"
          fit="contain"
        ></el-image>
        {{ i.title }}
      </el-col>
    </el-row>
    <div class="empty" v-if="!list.length && !loading">您暂无证书</div>
    <el-dialog
      :title="dialogVisible.title"
      v-model="dialogVisible.show"
      width="60%"
    >
      <el-image
        :src="dialogVisible.certImage"
        style="width: 100%"
        fit="contain"
      ></el-image>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "certificate",
  data() {
    return {
      loading: true,
      dialogVisible: {
        show: false,
        certImage: "",
        title: "",
      },
      list: [],
    };
  },
  methods: {
    showBigPic(item) {
      this.dialogVisible = {
        show: true,
        certImage: item.certImage,
        title: item.title,
      };
    },
    initData() {
      this.$https.get("/api/my/cert/list").then((res) => {
        if (res.success) {
          const data = res.data;
          this.list = data.map((el) => {
            return {
              ...el,
              certImage: "https://tecsf.gdcoh.com/" + el.certImage,
            };
          });
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="less">
.certificate {
  position: relative;
  padding: 50px 5%;
  .pic-box {
    text-align: center;
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
  }
}
</style>
